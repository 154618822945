import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Contain from '../styled/Contain'
import { H1 } from '../styled/Heading'
import { Intro } from '../styled/Text'
import Table from '../styled/Table'

const Discounts = props => {
  const data = useStaticQuery(graphql`
    query DiscountsQuery {
      allAirtable(filter: { table: { eq: "Kortingen" } }) {
        edges {
          node {
            data {
              Wie
              Wat
              Korting
            }
          }
        }
      }
    }
  `)

  const { title, text } = props

  // All discounts
  const discounts = data.allAirtable.edges

  // Get object keys for table head
  const tableHead = Object.keys(discounts[0].node.data)

  return (
    <DiscountWrapper>
      <Contain size="medium">
        <H1 as="h2" marginTop>
          {title}
        </H1>
      </Contain>
      <Contain size="small">
        {text && <Intro>{text}</Intro>}
        <Table>
          <thead>
            <tr>
              {tableHead.map(item => (
                <th key={item}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {discounts.map((row, index) => (
              <tr key={index}>
                <td data-title="Wie">{row.node.data.Wie}</td>
                <td data-title="Wat">{row.node.data.Wat}</td>
                <td data-title="Korting">{row.node.data.Korting}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Contain>
    </DiscountWrapper>
  )
}

Discounts.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default Discounts

const DiscountWrapper = styled.div`
  margin-top: 40px;

  @media (min-width: ${props => props.theme.layout.breakpoints.large}) {
    margin-top: 80px;
  }
`
