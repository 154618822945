import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Contain from '../styled/Contain'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Signup from '../components/forms/Signup'
import Prices from '../components/Prices'
import Discounts from '../components/Discounts'
import Banner from '../components/Banner'
import FAQ from '../components/Faq'
import bannerImage from '../images/lespartner.jpg'

export default function SignupPage({ data }) {
  return (
    <Layout>
      <Contain>
        <Signup
          title="Inschrijven"
          intro="Vul onderstaand formulier in om je in te schrijven."
          formName="inschrijven"
        />
        <Prices
          title="Tarieven"
          text="Bekijk hieronder de tarieven per cursus en welke kortingen er zijn voor studenten en anderen. Kortingen kunnen niet gecombineerd worden."
        />
        <Discounts title="Kortingen" />
        <Banner
          title={data.markdownRemark.frontmatter.title}
          html={data.markdownRemark.html}
          image={bannerImage}
          reverse
        />
        <FAQ
          currentPage="Inschrijven"
          intro="Dit zijn de meestgestelde vragen over het rooster en de tarieven, hier kun je het juiste antwoord vinden."
        />
      </Contain>
    </Layout>
  )
}

export const Head = () => <SEO title="Inschrijven" />

export const bannerSignupQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "partner" } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`

SignupPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired,
}
