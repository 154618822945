import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import styled from 'styled-components'

import {
  validationSchemaSignup,
  initialValuesSignup,
} from './validationSchemaSignup'
import { encode } from '../../utils/helpers'

import Dropdown from '../Dropdown'
import Student from './Student'
import { Button } from '../Button'
import Success from './Success'

import { H1 } from '../../styled/Heading'
import { Intro } from '../../styled/Text'
import { Form, FormActions } from '../../styled/Forms'
import Contain from '../../styled/Contain'

const Signup = props => {
  const data = useStaticQuery(graphql`
    query SignupQuery {
      allAirtable(filter: { table: { eq: "Toekomst" } }) {
        edges {
          node {
            data {
              Cursussen {
                data {
                  Naam
                  Niveau
                }
              }
              Dagen
            }
          }
        }
      }
    }
  `)

  const { title, intro, formName } = props

  // Array of all courses (including levels)
  const courses = data.allAirtable.edges

  const order = {
    Beginner: 1,
    Intermediate: 2,
    Advanced: 3,
    AdvancedXcombi: 4,
    default: 5,
  }

  const sortLevels = courses.sort(
    (a, b) =>
      (order[a.node.data.Cursussen[0].data.Niveau] || order.default) -
      (order[b.node.data.Cursussen[0].data.Niveau] || order.default)
  )

  // Sort courses by level
  const coursesGroupedByLevel = sortLevels.reduce(
    (accumulator, currentValue) => {
      // Level
      const level = currentValue.node.data.Cursussen.map(
        course => course.data.Niveau
      )

      // If there is no month yet create new array
      if (!accumulator[level]) {
        accumulator[level] = []
      }
      // Push level into corresponding array
      accumulator[level].push(currentValue)

      return accumulator
    },
    []
  )

  // Get all levels (eg. Basis, Advanced, Intermediate)
  const groupedLevels = Object.keys(coursesGroupedByLevel)

  // Get unique values
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  const [success, setSuccess] = useState(false)

  return (
    <SignupWrapper>
      <Contain size="medium">
        <H1 as="h2">{title}</H1>
      </Contain>
      <Contain size="small">
        <Intro>{intro}</Intro>
        {!success ? (
          <Formik
            initialValues={initialValuesSignup}
            validationSchema={validationSchemaSignup}
            onSubmit={(values, { setSubmitting }) => {
              fetch('/?no-cache=1', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: encode({
                  'form-name': formName,
                  ...values,
                }),
              })
                .then(() => {
                  setSuccess(!success)
                  setSubmitting(false)
                })
                .catch(error => {
                  setSuccess(false)
                  setSubmitting(false)
                })
            }}
            render={({ values, isSubmitting, handleSubmit }) => (
              <Form
                name={formName}
                method="POST"
                onSubmit={handleSubmit}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                {/* Needed for Netlify Forms to work properly */}
                {/* https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#step-2 */}

                <input type="hidden" name="form-name" value={formName} />

                <fieldset>
                  <Dropdown
                    label="Inschrijving"
                    name="inschrijving"
                    placeholder="Kies je inschrijving"
                    options={['Individu', 'Paar']}
                    md="1 / 3"
                  />
                  <Dropdown
                    label="Niveau"
                    name="niveau"
                    placeholder="Selecteer je niveau"
                    options={groupedLevels}
                    md="3 / -1"
                  />

                  {/* Only show if user filled in "signup field" and selected and "level" */}

                  {/* Get specific courses based on selected level */}
                  <Dropdown
                    isHidden={
                      !coursesGroupedByLevel[values.niveau] && 'is-hidden'
                    }
                    label="Cursus"
                    name="cursus"
                    placeholder="Selecteer cursus"
                    options={
                      coursesGroupedByLevel[values.niveau]
                        ? coursesGroupedByLevel[values.niveau]
                            .map(
                              course => course.node.data.Cursussen[0].data.Naam
                            )
                            .filter(onlyUnique)
                            .sort()
                        : []
                    }
                    md="1 / 4"
                  />

                  {/* Check which days the selected course is given within the "CURRENT" schedule */}
                  <Dropdown
                    isHidden={!values.cursus.length > 0 && 'is-hidden'}
                    label="Voorkeursdag"
                    name="voorkeursdag"
                    placeholder="Kies een voorkeursdag"
                    options={
                      coursesGroupedByLevel[values.niveau]
                        ? coursesGroupedByLevel[values.niveau]
                            .map(specificCourses => specificCourses)
                            .filter(onlyUnique)
                            .filter(
                              course =>
                                course.node.data.Cursussen[0].data.Naam ===
                                values.cursus
                            )
                            .map(day => day.node.data.Dagen)
                            .filter(onlyUnique)
                            .sort()
                        : []
                    }
                    md="4 / -1"
                  />
                </fieldset>

                {/* Determine if user want's to signup as a individual (show 1 field) or couple (show 2 fields) */}
                <fieldset
                  className={!values.voorkeursdag.length > 0 && 'is-hidden'}
                >
                  <legend>Cursist 1</legend>
                  <Student name="cursist1" />
                </fieldset>
                <fieldset
                  className={
                    values.inschrijving === 'Individu'
                      ? 'is-hidden'
                      : !values.voorkeursdag.length > 0
                      ? 'is-hidden'
                      : ''
                  }
                >
                  <legend>Cursist 2</legend>
                  <Student name="cursist2" />
                </fieldset>

                <FormActions>
                  <Button
                    type="submit"
                    buttonLabel="Versturen"
                    isDisabled={isSubmitting}
                  />
                </FormActions>
              </Form>
            )}
          />
        ) : (
          <Success />
        )}
      </Contain>
    </SignupWrapper>
  )
}

Signup.propTypes = {
  title: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
}

export default Signup

const SignupWrapper = styled.section`
  margin-top: 40px;

  @media (min-width: ${props => props.theme.layout.breakpoints.large}) {
    margin-top: 60px;
  }
`
