import React from 'react'
import PropTypes from 'prop-types'

import Dropdown from '../Dropdown'
import Input from '../Input'

const Student = props => {
  const { name } = props

  return (
    <>
      <Dropdown
        label="Aanhef"
        name={`aanhef${name}`}
        placeholder="Aanhef"
        options={['Dhr.', 'Mevr.']}
        md="1 / 3"
      />

      <Input label="Naam" name={`naam${name}`} placeholder="Naam" md="3 / -1" />

      <Input
        label="Telefoon"
        name={`telefoonnummer${name}`}
        placeholder="Type je telefoonnummer"
        type="phone"
        md="1 / 4"
      />

      <Input
        label="E-mail"
        name={`emailadres${name}`}
        placeholder="Type je e-mailadres"
        type="email"
        md="4 / -1"
      />
    </>
  )
}

Student.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Student
