import * as Yup from 'yup'

export const initialValuesSignup = {
  inschrijving: '',
  niveau: '',
  cursus: '',
  voorkeursdag: '',
  aanhefCursist1: '',
  naamCursist1: '',
  telefoonnummerCursist1: '',
  emailadresCursist1: '',
  aanhefCursist2: '',
  naamCursist2: '',
  telefoonnummerCursist2: '',
  emailadresCursist2: '',
}

const commonRequiredMessage = 'Verplicht'
const individual = 'Individu'
const couple = 'Paar'

export const validationSchemaSignup = Yup.object().shape({
  naamcursist1: Yup.string()
    .min(2, 'Te kort')
    .max(50, 'Te lang')
    .required(commonRequiredMessage),
  telefoonnummercursist1: Yup.string()
    .matches(/^(?=.*[0-9])[- +()0-9]+$/, {
      message: 'Alleen cijfers',
      excludeEmptyString: true,
    })
    .min(8, 'Te kort')
    .required(commonRequiredMessage),
  emailadrescursist1: Yup.string()
    .email('Vul een geldig e-mailadres in')
    .required(commonRequiredMessage),
  naamcursist2: Yup.string()
    .min(2, 'Te kort')
    .max(50, 'Te lang')
    .when('inschrijving', {
      is: val => val === couple,
      then: Yup.string().required(commonRequiredMessage),
    })
    .notRequired(),
  telefoonnummercursist2: Yup.string()
    .matches(/^(?=.*[0-9])[- +()0-9]+$/, {
      message: 'Alleen cijfers',
      excludeEmptyString: true,
    })
    .min(8, 'Te kort')
    .when('inschrijving', {
      is: val => val === couple,
      then: Yup.string().required(commonRequiredMessage),
    })
    .notRequired(),
  emailadrescursist2: Yup.string()
    .email('Vul een geldig e-mailadres in')
    .when('inschrijving', {
      is: val => val === couple,
      then: Yup.string().required(commonRequiredMessage),
    })
    .notRequired(),
})
