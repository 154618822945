import styled from 'styled-components'

const Table = styled.table`
  width: 100%;
  margin-top: 64px;
  border-collapse: collapse;
  text-align: left;

  thead {
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }

  tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 16px;
    border-bottom: none;
    box-shadow: inset 0 0 0 2px ${props => props.theme.colors.secondary};
  }

  td {
    position: relative;
    flex: 1 0 calc(50%);
    padding: 32px 16px 24px;
    border: 1px solid ${props => props.theme.colors.secondary};

    &:first-of-type {
      flex: 0 0 100%; /* compensate borders with negative margin */
      font-weight: ${props => props.theme.fontWeights.heading};
    }

    &:nth-child(2) {
      flex: 0 0 100%;
    }

    &::before {
      content: attr(data-title);
      position: absolute;
      top: 0;
      left: 0;
      padding: 4px;
      border-bottom-right-radius: 2px;
      background-color: ${props => props.theme.colors.secondary};
      color: ${props => props.theme.colors.background};
      font-size: 10px;
      font-weight: ${props => props.theme.fontWeights.heading};
      text-transform: uppercase;
    }
  }

  @media (min-width: ${props => props.theme.layout.breakpoints.medium}) {
    thead {
      visibility: visible;
      position: relative;
      left: auto;
    }

    tr {
      display: table-row;
      border-bottom: ${props => props.theme.borderWidth} solid
        ${props => props.theme.colors.secondary};
      box-shadow: none;

      + tr:last-of-type {
        border-bottom: none;
      }
    }

    td {
      margin: 0;
      padding: 32px;
      border: none;

      &::before {
        display: none;
      }

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }

    th {
      padding: 0 32px 16px;
      color: ${props => props.background && props.theme.colors.background};
      font-family: ${props => props.theme.fonts.heading};
      font-size: ${props => props.theme.fontSizes.h4};
      letter-spacing: ${props => props.theme.letterSpacings.h4};

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      svg {
        margin-right: 8px;
      }
    }
  }
`

export default Table
